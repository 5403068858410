export default function useWebchat(culture, siteSkin) {
  const exists = document.getElementById('magic-widget-launcher-parent');
  if (exists) return;

  const scriptElement = document.createElement('script');
  scriptElement.async = true;
  scriptElement.src = 'https://magicwidget.socialboards.com/magic-widget.js';
  document.body.appendChild(scriptElement);

  if (siteSkin !== 'VisitOslo') return;

  const o = {
    community: 'visitoslo',
    widgetId: 2060,
  };

  if (culture === 'no') {
    o.widgetId = 2057;
  }

  window.magicWidgetAsyncInit = function() {
    magicWidget
      .initializeAsync(o)
      .then(function() {
        // initialization was successfull!
        console.log('Webchat initialized');
      })
      .catch(function(error) {
        // an error occured while initializing.
        console.log('Webchat failed to initialize', error);
      });
  };
}
